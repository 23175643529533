// SignUpDialog.tsx
import React, { useCallback, useEffect } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import SignUp from "@/features/auth/Signup";
import { LOCALIZATION_KEYS } from "@/i18n/keys";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import GradientButton from "../ui/gradient-button";
import { toggleSignUpDialog } from "@/features/auth/UserSlice";

// SignUpDialog component: Wraps the SignUp form inside a dialog
const SignUpDialog: React.FC = () => {
  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);

  const open = useSelector(
    (state: RootState) => state.rootReducer.userReducer.signUpDialog
  );

  const user = useSelector(
    (state: RootState) => state.rootReducer.userReducer.user
  );

  const dispatch = useDispatch<AppDispatch>();

  const setOpen = useCallback(
    (state: boolean) => {
      dispatch(toggleSignUpDialog(state));
    },
    [dispatch]
  );

  useEffect(() => {
    console.log("new logged in user");
    if (user) setOpen(false);
  }, [user, setOpen]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {/* Trigger for opening the dialog */}
      <DialogTrigger asChild>
        <GradientButton className="text-[14px]">
          {t(LOCALIZATION_KEYS.NAV_LINKS.SIGN_UP)}
        </GradientButton>
      </DialogTrigger>

      {/* Dialog Content */}
      <DialogContent
        aria-description="Sign up to BMP"
        aria-describedby="sign-up-dialog-description"
        className="w-[60vw]"
      >
        <DialogHeader className="border-b pb-2">
          <DialogTitle className="text-center font-sans mt-[-10px] text-[18px] font-semibold leading-[20px] text-black">
            Sign up
          </DialogTitle>
          <DialogDescription hidden>
            Enter your email and create a password to sign up for BuildMapper.
          </DialogDescription>
        </DialogHeader>

        {/* SignUp form inside the dialog */}
        <div>
          <SignUp />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpDialog;
