import "./App.css";
import { Provider } from "react-redux";
import { store } from "@/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Header } from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import { ThemeProvider } from "@/contexts/ThemeProvider";
import { Outlet, useLocation } from "react-router-dom";
import { ScrollArea, ScrollBar } from "./components/ui/scroll-area";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingProvider } from "@/contexts/LoadingContext";
import Loader from "@/components/Loader";
import packagejson from "../package.json";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import { TooltipProvider } from "./components/ui/tooltip";
import ErrorBoundary from "./contexts/ErrorBoundaryContext";
import ErrorPage from "./components/ErrorPage";
import "react-date-range/dist/styles.css"; // Main styles
import "react-date-range/dist/theme/default.css"; // Default theme

function App() {
  console.log("-------- app version --------", packagejson.version);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8fdda3", // Change primary color
      },
      secondary: {
        main: "#7dce93", // Change secondary color
      },
      // You can customize other colors as needed
      background: {
        default: "#f5f5f5", // Change default background color
      },
    },
    typography: {
      fontFamily: '"Outfit", "Arial", "sans-serif"',
      fontSize: 14,
    },
  });
  const location = useLocation();

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <MUIThemeProvider theme={theme}>
            <LoadingProvider>
              <TooltipProvider>
                <ErrorBoundary fallback={<ErrorPage />}>
                  <Loader />
                  <ScrollArea className="h-full">
                    {!location.pathname.includes("dashboard") && <Header />}
                    <Outlet />
                    {!location.pathname.includes("dashboard") && <Footer />}
                    <ToastContainer />
                    <ScrollBar />
                  </ScrollArea>
                </ErrorBoundary>
              </TooltipProvider>
            </LoadingProvider>
          </MUIThemeProvider>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
