import { createRoot } from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import router from "@/routes";
import { onMessageListener } from "./firebase";

// Handle incoming messages
onMessageListener()
  .then((payload) => {
    console.log("Message received. ", payload);
    alert(`New notification: ${payload.notification?.title}`);
  })
  .catch((err) => console.log("Failed to receive message: ", err));

createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />
);
