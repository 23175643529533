import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "../App";
import Home from "@/pages/home/home";
import CRMLayout from "@/features/crm/layout";

import { Suspense } from "react";
import {
  BlogDetails,
  BlogsListing,
  KanbanBoard,
  LeadDetails,
  Listing,
  ComingSoon,
  Calendar,
  Contacts,
  AddLead,
  ListView,
  ActivityView,
} from "./lazyImports";
import Loading from "./loading";

// import ProtectedRoute from "./protected-route";

{
  /* fallback={
    <div className="loading-overlay">
      <div className="spinner z-50">
        <GridLoader color="#28af4a" />
      </div>
    </div>
  } */
}

// const isAuth = Boolean(localStorage.getItem("accessToken"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="dashboard" element={<CRMLayout />}>
        <Route
          index
          path="crm"
          element={
            <Suspense fallback={<Loading />}>
              <KanbanBoard />
            </Suspense>
          }
        />
        <Route
          path="leads/:id"
          element={
            <Suspense fallback={<Loading />}>
              <LeadDetails />
            </Suspense>
          }
        />
        <Route
          path="crm/add_lead"
          element={
            <Suspense fallback={<Loading />}>
              <AddLead />
            </Suspense>
          }
        />
        <Route
          path="explore_leads"
          element={
            <Suspense fallback={<Loading />}>
              <Listing />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <ComingSoon />
            </Suspense>
          }
        />
        <Route
          path="calendar"
          element={
            <Suspense fallback={<Loading />}>
              <Calendar />
            </Suspense>
          }
        />
        <Route
          path="crm/list_view"
          element={
            <Suspense fallback={<Loading />}>
              <ListView />
            </Suspense>
          }
        />
        <Route
          path="crm/activity_view"
          element={
            <Suspense fallback={<Loading />}>
              <ActivityView />
            </Suspense>
          }
        />
        <Route
          path="contacts"
          element={
            <Suspense fallback={<Loading />}>
              <Contacts />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="blogs"
        element={
          <Suspense fallback={<Loading />}>
            <BlogsListing />
          </Suspense>
        }
      />
      <Route
        path="blogs/:id"
        element={
          <Suspense fallback={<Loading />}>
            <BlogDetails />
          </Suspense>
        }
      />
    </Route>
  )
);

export default router;
