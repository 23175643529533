import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { HomeIcon } from "lucide-react";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-green-50 to-white">
      <div className="w-full flex flex-col items-center justify-center min-h-screen px-4 text-center">
        <div className="space-y-4 mb-8">
          <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl">
            Something Went Wrong
          </h1>
          <p className="text-lg text-muted-foreground md:text-xl">
            We apologize for the inconvenience. Please try again later.
          </p>
        </div>

        {/* Decorative illustration */}
        <div className="relative w-full max-w-2xl mx-auto my-8">
          <div className="aspect-[2/1] bg-green-100/50 rounded-lg flex items-center justify-center">
            <svg
              className="w-32 h-32 text-green-500/20"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M13 2.5L5 7.119V15.5L13 20.119L21 15.5V7.119L13 2.5ZM20 7.119L13 11L6 7.119L13 3.238L20 7.119ZM5 8.881L12 12.762V19.381L5 15.5V8.881ZM13 19.381V12.762L20 8.881V15.5L13 19.381Z" />
            </svg>
          </div>
        </div>

        {/* <Link to="" className="inline-flex items-center gap-2 !no-underline"> */}
        <Button
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
          className="flex gap-1 items-center"
        >
          <HomeIcon className="w-4 h-4" />
          Back to Homepage
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
}
