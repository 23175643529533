// Login.tsx
// This component handles user authentication through email/password
// and social media login options (Facebook, Google, Apple)
import React, { useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { TypographyP } from "@/components/typography/TypographyP";
import { Label } from "@/components/ui/label";
import fbIcon from "@/assets/icons/social/fb.svg";
import googleIcon from "@/assets/icons/social/google.svg";
import appleIcon from "@/assets/icons/social/apple.svg";
import { useDispatch } from "react-redux";
import {
  setUser,
  signInUser,
  toggleloginDialog,
  toggleQuestion,
  toggleSignUpDialog,
} from "./UserSlice";
import { AppDispatch } from "@/store";
import GradientButton from "@/components/ui/gradient-button";
import { useLoading } from "@/contexts/LoadingContext";
import { PasswordInput } from "@/components/ui/password-input";
import { User } from "./types";

// Define the structure of form data
interface SignUpFormInputs {
  email: string;
  password: string;
}

// Login component that handles user authentication
// Props:
// - setOpen: Function to control the visibility of the login dialog
const Login: React.FC<{ setOpen: (state: boolean) => void }> = ({
  setOpen,
}) => {
  // Initialize react-hook-form with validation and error handling
  const {
    register,        // Function to register form inputs with validation
    handleSubmit,    // Form submission handler
    formState: { errors }, // Object containing form validation errors
  } = useForm<SignUpFormInputs>();

  const dispatch = useDispatch<AppDispatch>();
  const { setLoading } = useLoading(); // Loading state for API calls

  // Form submission handler
  // 1. Makes API call to authenticate user
  // 2. Updates global state with user data
  // 3. Updates questionnaire status
  // 4. Closes the login dialog on success
  const onSubmit: SubmitHandler<SignUpFormInputs> = (data) => {
    console.log(data); // You can add your API call here
    const credentials = {
      email: data.email,
      password: data.password,
    };

    setLoading(true);
    dispatch(signInUser(credentials))
      .then((result) => {
        console.log("sign up result", result); // You can add your API call here
        const user = result.payload as User;
        if (user) {
          dispatch(setUser(user));
          dispatch(toggleQuestion(user.questionnaireFilled));
          setOpen(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Switches from login to signup dialog
  // Closes login dialog and opens signup dialog
  const openSignUpDialog = useCallback(() => {
    console.log("openSignUpDialog called");
    dispatch(toggleSignUpDialog(true));
    dispatch(toggleloginDialog(false));
  }, [dispatch]);

  // Style definitions for consistent UI components
  const labelStyle = "block text-[14px] leading-[20px] text-black font-medium";
  // Dynamic style for input fields - shows red border on error
  const inputStyle = (errors: unknown) =>
    `border-[#cbd5e1] ${errors ? "!border-red-600" : ""}`;
  // Style for social login buttons with consistent layout
  const socialButtonStyle =
    "flex items-center justify-center w-full text-black font-semibold border-lightGray";
  // Style for social login button icons
  const socialButtonIconStyle = "flex justify-start w-6 h-6 mr-2";

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {/* Form title */}
      <div className="">
        <TypographyP className="text-[18px] leading-[26px] font-semibold">
          Login to your account
        </TypographyP>
      </div>

      {/* Email input field with validation */}
      {/* Shows error message if validation fails */}
      <div>
        <Label htmlFor="email" className={labelStyle}>
          Email
        </Label>
        <Input
          id="email"
          placeholder="Enter Email Address"
          className={inputStyle(errors.email)}
          type="email"
          {...register("email", {
            required: "Email is required",
          })}
        />
        {errors.email && (
          <p className="text-red-600 text-xs">{errors.email.message}</p>
        )}
      </div>

      {/* Password input field with validation */}
      {/* Uses custom PasswordInput component with show/hide functionality */}
      <div>
        <Label htmlFor="password" className={labelStyle}>
          Password
        </Label>
        <PasswordInput
          id="password"
          placeholder="Create Password"
          className={inputStyle(errors.password)}
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
            maxLength: {
              value: 12,
              message: "Password should not exceed 12 characters",
            },
          })}
        />
        {errors.password && (
          <p className="text-red-600 text-xs">{errors.password.message}</p>
        )}
      </div>

      {/* Submit button with gradient background */}
      <GradientButton id="submit-button" type="submit" className="w-full mt-4">
        Continue
      </GradientButton>

      {/* Divider between email/password and social login options */}
      <div className="flex items-center justify-center my-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="px-2 text-sm text-black font-medium">or</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>

      {/* Social login buttons */}
      {/* Each button includes an icon and centered text */}
      <Button variant="outline" className={socialButtonStyle}>
        <span className={socialButtonIconStyle}>
          <img src={fbIcon} alt="Facebook" /> {/* Facebook icon */}
        </span>
        <span className="flex-1 text-center">Continue with Facebook</span>
      </Button>
      <Button variant="outline" className={socialButtonStyle}>
        <span className={socialButtonIconStyle}>
          <img src={googleIcon} alt="Google" /> {/* Google icon */}
        </span>
        <span className="flex-1 text-center">Continue with Google</span>
      </Button>
      <Button variant="outline" className={socialButtonStyle}>
        <span className={socialButtonIconStyle}>
          <img src={appleIcon} alt="Apple" /> {/* Apple icon */}
        </span>
        <span className="flex-1 text-center">Continue with Apple</span>
      </Button>

      {/* Footer section with forgot password and signup links */}
      <div className="w-full justify-center items-end mt-5">
        {/* Login Link */}
        <TypographyP className="text-black text-[14px] leading-[20px] text-center !mt-1">
          Forgot your password?{" "}
          <a href="#" className="text-bmpGreen-600 hover:text-bmpGreen-800">
            Click here
          </a>
        </TypographyP>

        <div className="flex justify-center items-center gap-1">
          {/* Terms & Conditions */}
          <TypographyP className="text-[14px] leading-[20px] text-black text-center">
            Don’t have an account?{" "}
          </TypographyP>
          <Button
            id="signup-button"
            type="button"
            variant="link"
            onClick={openSignUpDialog}
            className="text-bmpGreen-600 hover:text-bmpGreen-800 p-0"
          >
            Sign up
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Login;
