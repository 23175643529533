// SignUpDialog.tsx
// import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { LOCALIZATION_KEYS } from "@/i18n/keys";
import { useTranslation } from "react-i18next";
import Login from "@/features/auth/Login";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { toggleloginDialog } from "@/features/auth/UserSlice";
// import { useSelector } from "react-redux";
// import { RootState } from "@/store";

// SignUpDialog component: Wraps the SignUp form inside a dialog
const LoginDialog: React.FC = () => {
  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);
  const open = useSelector(
    (state: RootState) => state.rootReducer.userReducer.loginDialog
  );

  const dispatch = useDispatch<AppDispatch>();

  const setOpen = useCallback(
    (state: boolean) => {
      dispatch(toggleloginDialog(state));
    },
    [dispatch]
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {/* Trigger for opening the dialog */}
      <DialogTrigger asChild>
        <Button className="font-medium" variant="outline">
          {t(LOCALIZATION_KEYS.NAV_LINKS.LOGIN)}
        </Button>
      </DialogTrigger>

      {/* Dialog Content */}
      <DialogContent
        aria-description="Sign up to BMP"
        aria-describedby="sign-up-dialog-description"
      >
        <DialogHeader className="border-b pb-2">
          <DialogTitle className="text-center font-sans mt-[-10px] text-[18px] font-semibold leading-[20px] text-black">
            {t(LOCALIZATION_KEYS.NAV_LINKS.LOGIN)}
          </DialogTitle>
          <DialogDescription hidden>
            Enter your email and create a password to sign up for BuildMapper.
          </DialogDescription>
        </DialogHeader>

        {/* SignUp form inside the dialog */}
        <Login setOpen={setOpen} />
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
