import { lazy } from "react";

export const KanbanBoard = lazy(() => import("@/features/kanban/KanbanBoard"));

export const LeadDetails = lazy(() => import("@/pages/Leads/LeadDetails"));

export const Listing = lazy(() => import("@/features/project-listing/Layout"));

export const BlogsListing = lazy(
  () => import("@/features/blogs/list-blogs-layout")
);

export const BlogDetails = lazy(() => import("@/features/blogs/blog-page"));

export const ComingSoon = lazy(() => import("@/features/crm/coming-soon"));

export const Calendar = lazy(() => import("@/features/calendar/Calendar"));

export const AddLead = lazy(() => import("@/features/crm/addLeads/add-leads"));

export const ListView = lazy(
  () => import("@/features/kanban/views/listing-view")
);

export const ActivityView = lazy(
  () => import("@/features/kanban/views/activity-view")
);

export const Contacts = lazy(() => import("@/features/contacts/Contacts"));
