// src/firebase.ts
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  onMessage,
  Messaging,
  MessagePayload,
  getToken,
} from "firebase/messaging";
import axiosInstance from "./api/axios";
// import axiosInstance from "./api/axios";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_API_KEY,
  authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_APP_ID,
  measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging and export
const messaging: Messaging = getMessaging(app);

export const saveToken = async () => {
  try {
    // setLoading(true);
    const accessToken = localStorage.getItem("accessToken");
    const token = sessionStorage.getItem("notificationToken");
    if (accessToken && token) {
      const response = await axiosInstance({
        method: "POST",
        url: "/user/api/v1/deviceToken/push",
        data: {
          token: token,
          platform: 3,
        },
      });
      console.log("save token response", response);
    }
  } catch (e) {
    console.log(e);
  }
};

// Function to request permission and get FCM token
export const requestForToken = async (): Promise<string | undefined> => {
  try {
    const permission = await Notification.requestPermission();
    console.log("notification requested", permission);
    if (permission === "granted") {
      const currentToken = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_APP_VAPID_KEY,
      });
      if (currentToken) {
        console.log("FCM Token:", currentToken);
        sessionStorage.setItem("notificationToken", currentToken);
        await saveToken();
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return undefined;
      }
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};

// Function to handle incoming messages
export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
