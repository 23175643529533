import { useLoading } from "@/contexts/LoadingContext";
import { useEffect } from "react";

function Loading() {
  const { setLoading } = useLoading();
  useEffect(() => {
    setLoading(true); // Set loading to true when component mounts

    return () => {
      setLoading(false); // Clean up and set loading to false when component unmounts
    };
  }, [setLoading]);

  return (
    <>
      <div></div>
    </>
  );
}

export default Loading;
